<!DOCTYPE html>
<html>

<head>
  <title>Tailwind CSS Carousel</title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss/dist/tailwind.min.css" rel="stylesheet">
</head>

<body>
  <section class="text-gray-700 body-font overflow-hidden">
    <div class="container px-5 py-14 mx-auto overflow-hidden">
      <div class="flex flex-wrap -m-4 overflow-hidden">
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full overflow-hidden">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/python.png">
          </a>
          <figcaption class="wp-caption-text font-black-100">Python</figcaption>

        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/django.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Django</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block" lazyLoad="../../assets/Images/Images/js.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Java Script</figcaption>

        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/angularjs.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Angular</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/Csharp.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">C#</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block" lazyLoad="../../assets/Images/Images/aws.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Amazon Webservice</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/android.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Android</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block" lazyLoad="../../assets/Images/Images/bs.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Bootstrap</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center w-full h-full block"
              lazyLoad="../../assets/Images/Images/digitalocean.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Digital Ocean</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/docker.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Docker</figcaption>

        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center  block"
              lazyLoad="../../assets/Images/Images/flutter.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Flutter</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/googlecloud.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Google Cloud</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/perl.png">
          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Perl</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/react.png">

          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">React native</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/mongo.png">

          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">MongoDB</figcaption>
        </div>

        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/firebase.png">

          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Firebase</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/Onsen.png">

          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Onsen UI</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/postgresql.png">

          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">PostgreSQL</figcaption>
        </div>
        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/MatlabLogo.png">

          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">MATLAB</figcaption>
        </div>

        <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden">
            <img alt="ecommerce" class="object-cover object-center block"
              lazyLoad="../../assets/Images/Images/java.png">

          </a>
          <figcaption class="widget-image-caption wp-caption-text font-black-100">Java</figcaption>
        </div>



      </div>
    </div>
  </section>
</body>

</html>
