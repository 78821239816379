<div>
  <div fxFlex="20" class="row">
    <app-header></app-header>
  </div>
  <div fxFlex="60" class="row" class=" position-relative">
    <router-outlet></router-outlet>
  </div>
  <div fxFlex class="position-relative uk-position-bottom">
    <app-footer></app-footer>
  </div>
</div>
