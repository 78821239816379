<!DOCTYPE html>
<html>

<head>
  <title>Tailwind CSS Carousel</title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss/dist/tailwind.min.css" rel="stylesheet">
</head>

<body>

  <section class="text-gray-700 body-font">
    <div class="container px-5 py-15 mx-auto overflow-hidden">
      <div class="flex flex-col text-center w-full mb-20">
        <h1 class="text-2xl font-medium title-font mb-4 text-blue-900 tracking-widest" style="padding-top: 35px;">OUR
          TEAM</h1>
      </div>
      <div class="flex flex-wrap -m-4">
        <div class="p-4 lg:w-1/2">
          <div
            class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
            <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
              lazyLoad="../../assets/Images/teammembers/monalisa.png">
            <div class="flex-grow sm:pl-8">
              <h2 class="title-font font-medium text-xl text-blue-900">Monalisa Patnaik</h2>
              <h3 class="text-gray-900 mb-3">Founder</h3>
              <p class="mb-4 text-lg text-justify font-black-100">I am a technical graduate with more than ten years of
                relevant
                experience in software languages such as Angular, C#, WPF for
                windows, mobile, and web application development.

              </p>

            </div>
          </div>
        </div>
        <div class="p-4 lg:w-1/2">
          <div
            class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
            <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
              lazyLoad="../../assets/Images/teammembers/nophoto.png">
            <div class="flex-grow sm:pl-8">
              <h2 class="title-font font-medium text-xl text-blue-900">Udaykiran Patnaik</h2>
              <h3 class="text-gray-900 mb-3">Sr. Advisor</h3>
              <p class="mb-4 text-lg text-justify font-black-100">I am a graduate in Electronics and Communication
                Engineering
                having more than 11 years of industrial experience into
                the area in Automotive ECU Software Development and Artificial
                Intelligence.
              </p>

            </div>
          </div>
        </div>
        <div class="p-4 lg:w-1/2">
          <div
            class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
            <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
              lazyLoad="../../assets/Images/teammembers/nophoto.png">
            <div class="flex-grow sm:pl-8">
              <h2 class="title-font font-medium text-xl text-blue-900">Manisha Panda</h2>
              <h3 class="text-gray-900 mb-3">Sr. Advisor</h3>
              <p class="mb-4 text-lg text-justify font-black-100">
                I am a Master of Science graduate with five years of full time industrial IT experience.
                I have hands on experience in Android, website and standalone application.

              </p>
            </div>
          </div>
        </div>
        <div class="p-4 lg:w-1/2">
          <div
            class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
            <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
              lazyLoad="../../assets/Images/teammembers/nophoto.png">
            <div class="flex-grow sm:pl-8">
              <h2 class="title-font font-medium text-xl text-blue-900">Navya Anand</h2>
              <h3 class="text-gray-900 mb-3">Advisor</h3>
              <p class="mb-4 text-lg text-justify font-black-100"> I am an experienced and successful professional
                software
                developer in analyzing, designing and implementing
                Java-based web and standalone applications. I have almost 9+
                yrs of experience in IT.
              </p>

            </div>
          </div>
        </div>


        <div class="p-4 lg:w-1/2">
          <div
            class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
            <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
              lazyLoad="../../assets/Images/teammembers/poonam.png">
            <div class="flex-grow sm:pl-8">
              <h2 class="title-font font-medium text-xl text-blue-900">Poonam Saini</h2>
              <h3 class="text-gray-900 mb-3">Advisor</h3>
              <p class="mb-4 text-lg text-justify font-black-100">I am an experienced professional in developing
                applications on
                Java, Spring, AWS, MYSQL, and NOSQL. I have almost 7+ yrs of IT experience.
              </p>

            </div>
          </div>
        </div>
        <div class="p-4 lg:w-1/2">
          <div
            class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
            <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
              lazyLoad="../../assets/Images/teammembers/nophoto.png">
            <div class="flex-grow sm:pl-8">
              <h2 class="title-font font-medium text-xl text-blue-900">Roshan Ranjan Behera</h2>
              <h3 class="text-gray-900 mb-3">Designer/Advisor</h3>
              <p class="mb-4 text-lg text-justify font-black-100">I am a Mechanical graduate with more than seven years
                of professional
                experience in Advanced Planning and Scheduling. I
                work as a Functional consultant to understand the customer process, functional and technical
                requirements.
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</body>

</html>
