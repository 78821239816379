import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import {
  AccumulationChartModule,
  AccumulationDataLabelService,
  PieSeriesService,
  AccumulationLegendService,
  AccumulationTooltipService,
} from '@syncfusion/ej2-angular-charts';

import {
  NavbarModule,
  WavesModule,
  ButtonsModule,
  IconsModule,
  MDBBootstrapModule,
  CarouselModule,
} from 'angular-bootstrap-md';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BritechsoftComponent } from './britechsoft/britechsoft.component';
import { HomeComponent } from './britechsoft/home/home.component';
import { ProductComponent } from './britechsoft/product/product.component';
import { HeaderComponent } from './britechsoft/header/header.component';
import { FooterComponent } from './britechsoft/footer/footer.component';
import { AboutComponent } from './britechsoft/about/about.component';
import { ContactComponent } from './britechsoft/contact/contact.component';
import { PrivacyPolicyComponent } from './britechsoft/privacy-policy/privacy-policy.component';
import { CareerComponent } from './britechsoft/career/career.component';
import { ImageCarouselComponent } from './britechsoft/image-carousel/image-carousel.component';
import { TechnologyComponent } from './britechsoft/technology/technology.component';
import { OurTeamComponent } from './britechsoft/our-team/our-team.component';
import { HomeWhatWhyComponent } from './britechsoft/home-what-why/home-what-why.component';
import { AboutWhoWhyComponent } from './britechsoft/about-who-why/about-who-why.component';
import { ContactQueryComponent } from './britechsoft/contact-query/contact-query.component';
@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    BritechsoftComponent,
    HomeComponent,
    ProductComponent,
    HeaderComponent,
    FooterComponent,
    CareerComponent,
    ImageCarouselComponent,
    TechnologyComponent,
    OurTeamComponent,
    HomeWhatWhyComponent,
    AboutWhoWhyComponent,
    ContactQueryComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NavbarModule,
    WavesModule,
    ButtonsModule,
    IconsModule,
    MDBBootstrapModule.forRoot(),
    CarouselModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    AccumulationChartModule,
    LazyLoadImageModule
  ],
  providers: [
    PieSeriesService,
    AccumulationDataLabelService,
    AccumulationLegendService,
    AccumulationTooltipService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
