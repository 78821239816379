import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Email } from '../../../assets/smtp/smtp.js';
declare let Email: any;
@Component({
  selector: 'app-contactquery',
  templateUrl: './contact-query.component.html',
  styleUrls: ['./contact-query.component.scss']
})
export class ContactQueryComponent implements OnInit {
  file: File;
  Name: string;
  EmailId: string;
  ResumeOutsideText: string;
  constructor() { }

  ngOnInit(): void {
  }
  fileChange(file) {
    this.file = file.target.files[0];
    console.log(this.file.name);
  }
  onSubmit(form: NgForm) {
    if (form.invalid) {
      let controls: string[] = Object.keys(form.controls);
      for (let i = 0; i < controls.length; i++) {
        const key = controls[i];
        if (form.controls[key].invalid) {
          return;
        }
      }
      return;
    }
    else {

      Email.send({
        Host: 'smtp.elasticemail.com',
        Username: 'britechsoft@gmail.com',
        Password: '06C95D9FAFE8DDFADA82DD0A298B93037AE7',
        To: 'britechsoft@gmail.com',
        From: 'britechsoft@gmail.com',
        Subject: 'Contactus- Query',
        Body: `
    <i>This is sent as a feedback from my resume page.</i>
    <br/> <b>Name: </b>${form.value.Name} <br />
    <b>Email: </b>${form.value.EmailId}<br />
    <b>Resume OutsideText: </b>${form.value.ResumeOutsideText}<br />
     <br> <b>~End of Message.~</b> `
      }).then(message => { alert('Mail sent successfully.'); form.resetForm(); });
    }
  }
}
