<div fxLayout="column" class="scroll-hidden bgWhite" layout-align="start stretch" style="margin-top: 50px">
  <h1 class="text-2xl font-medium title-font mb-4 text-blue-900 tracking-widest"
    style="text-align: center; padding-top: 25px">
    JOB APPLICATION <br>

  </h1>
  <section class="text-gray-700 body-font relative">
    <div class="lg:w-1/2 md:w-2/3 mx-auto" style="text-align: center">
      <p style="color: #5e17eb"> Fill in the below form to apply for
        the interested job
        position</p>
      <br />
      <br />
      <div style="text-align: center">

        <form #contactusform="ngForm" class="uk-form" novalidate>
          <div class="form-group row">
            <input required class="w-full bg-gray-100 rounded border border-gray-400 focus:outline-none
              focus:border-indigo-500 text-base px-4 py-2 p-0 mr-10 col-lg-12 col-md-6 col-sm-3" placeholder="*Job ID"
              type="text" ngModel name="JobID" id="JobID" #JobID="ngModel" />
            <div class="validate-message text-danger" *ngIf="JobID.invalid">
              Please enter Job ID
            </div>
          </div>
          <div class="form-group row">
            <input required class="w-full bg-gray-100 rounded border border-gray-400
              focus:outline-none focus:border-indigo-500 text-base px-4 py-2 p-0 mr-10 col-lg-12 col-md-6 col-sm-3"
              placeholder="*Phone Number" type="text" ngModel name="PhoneNumber" id="PhoneNumber"
              #PhoneNumber="ngModel" />
            <div class="validate-message text-danger" *ngIf="PhoneNumber.invalid">
              Please enter PhoneNumber
            </div>
          </div>
          <div class="form-group row">
            <input required class="w-full bg-gray-100 rounded border border-gray-400
              focus:outline-none focus:border-indigo-500 text-base px-4 py-2 p-0 mr-10 col-lg-12 col-md-6 col-sm-3"
              placeholder="*Name" type="text" ngModel name="Name" id="Name" #Name="ngModel" />
            <div class="validate-message text-danger" *ngIf="Name.invalid">
              Please enter Name
            </div>

          </div>
          <div class="form-group row">
            <input required class="w-full bg-gray-100 rounded border border-gray-400
              focus:outline-none focus:border-indigo-500 text-base px-4 py-2 p-0 mr-10 col-lg-12 col-md-6 col-sm-3"
              placeholder="*Email" type="email" ngModel name="EmailId" id="EmailId" #EmailId="ngModel" />
            <div class="validate-message text-danger" *ngIf="EmailId.invalid">
              Please enter Email id
            </div>
          </div>
          <div class="form-group row">
            <textarea
              class="w-full bg-gray-100 rounded border border-gray-400 focus:outline-none h-48 focus:border-indigo-500 text-base px-4 py-2 resize-none block"
              placeholder="Copy and paste your resume" ngModel name="ResumeText"></textarea>
          </div>
          <div class="form-group row">
            <textarea
              class="w-full bg-gray-100 rounded border border-gray-400 focus:outline-none h-48 focus:border-indigo-500 text-base px-4 py-2 resize-none block"
              placeholder="Write something about you which is not mentioned in the resume" ngModel
              name="ResumeOutsideText"></textarea>
          </div>
          <div class="p-2 w-full">
            <button class="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8
              focus:outline-none hover:bg-indigo-600 rounded text-lg" type="submit"
              (click)="onSubmit(contactusform)">Send</button>
          </div>
        </form>

      </div>
      <br />
    </div>

    <br/>
       <hr />
    <br/>

    <div class=" center">
      <img class="w-50 center" alt="we are hiring now!" lazyLoad="../../assets/Images/contactus.jpg" />
    </div>


    <div class="h-full flex sm:flex-row flex-col " style="text-align: center;">

      <div class="flex-grow sm:pl-8" style="text-align: center;">
        <h3 class="text-xl text-blue-900">Our Address In Japan</h3>
        <br />
        <div class="font-black-100">
        〒 242-0006 Kanagawa Prefecture <br />
        1-8-15 Nishitsuruma, Yamato-shi <br />
        </div>
        <br />
        <h3 class="text-xl text-blue-900">Our Address In India</h3>
        <br />
        <div class="font-black-100">
        Adithya Pristine Apt, Kadugodi <br />
        Bangalore- 560067 <br />
        </div>
      </div>
      <div class="flex-grow sm:pl-8" style="text-align: center;">
        <h3 class=" text-xl text-blue-900">Business Hours</h3>
        <br />
        <p class="font-black-100">
          Mon: 08:00 AM – 10:00 PM<br />
          Tue: 08:00 AM – 10:00 PM<br />
          Wed: 08:00 AM – 10:00 PM<br />
          Thu: 08:00 AM – 10:00 PM<br />
          Fri: 08:00 AM – 10:00 PM<br />
          Sat: 08:00 AM – 10:00 PM<br />
          Sun: 08:00 AM – 10:00 PM<br />
        </p>

      </div>
      <div class="flex-grow sm:pl-8" style="text-align: center;">
        <div class="p-2 w-full pt-8 mt-8 ">
          <h3 class=" text-xl text-blue-900">Contact Details</h3>
          <br />
          <p class="font-black-100">We are available on the following details.</p>
          <a class="font-black-100 phone-email"> Email : britechsoft@gmail.com</a><br />
          <a class="font-black-100 phone-email"> Phone : +81 70-4403-2825</a><br />
          <a class="font-black-100 phone-email"> whatsapp : +91 7760082874</a><br />
          <p class="font-black-100">We look forward to hearing from you soon.</p>
        </div>

      </div>
    </div>
    <br /> <br />
  </section>
</div>
