<section class="text-gray-700 body-font">
  <div class="container px-5 py-14 mx-auto flex flex-col">
    <div class=" mx-auto">
      <!--      <div class="rounded-lg h-64 overflow-hidden">-->
      <!--        <img alt="content" class="object-cover object-center h-full w-full" lazyLoad="../../assets/new-back.png" style="alignment:center;width:600px;height:300px;">-->
      <!--      </div>-->
      <div class="flex flex-col sm:flex-row mt-10">
        <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
          <div class="flex flex-col items-center text-center justify-center">
            <img class="h-full" style="width: 200px; height: 300px; margin-top: 70px;"
              lazyLoad="../../assets/Images/Textimages/whoweare.png" />
            <!-- <h1 class="font-medium title-font mt-4 text-gray-900 text-2xl">Who we are?</h1>

            <div class="w-12 h-1 bg-orange-500 rounded mt-2 mb-4"></div> -->
          </div>
        </div>

        <div class="sm:w-2/3 sm:pl-8 sm:py-8  mt-4 pt-4 sm:mt-0 text-center sm:text-left">
          <h1 class="font-medium title-font mt-4 text-2xl" style="color: orangered;">
            Who we are?
          </h1>
          <br />
          <p class="leading-relaxed text-xl text-justify font-black-100">
            Brilliant Technology Software is known as BriTechSoft, is a software
            company that provides services on various websites, mobile, and
            standalone applications based on customer needs. We expertise in
            multiple programming languages such as Java, JavaScript, .Net,
            Python, and many more. Our team consists of a UI/UX designer, a
            marketing expert, a fantastic content writer, and five highly
            qualified software developers mastering various cutting edge
            technologies. BriTechSoft has brilliant and young talents who make
            the team agile and flexible—based in Bangalore since May 2020 by
            Monalisa Patnaik, an expert in the software industry with more than
            10years of experience. She has handled national and international
            clients, including Japan, the USA, Germany, and India. Her team and
            client management skills help the team and client understand each
            other’s needs well. She excels in the technical aspect, also with
            the business solutions making the products user friendly.
          </p>
        </div>
      </div>
      <div class="w-full h-1 bg-gray-100 rounded mt-2 mb-4"></div>
      <div class="flex flex-col sm:flex-row mt-10">
        <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
          <div class="flex flex-col items-center text-center justify-center">
            <img class="h-full" style="width: 200px; height: 300px; margin-top: 50px;"
              lazyLoad="../../assets/Images/Textimages/whyus.png" />
            <!-- <h2 class="font-medium title-font mt-4 text-gray-900 text-2xl">
              Why us?
            </h2>
            <div class="w-12 h-1 bg-orange-500 rounded mt-2 mb-4"></div> -->
          </div>
        </div>
        <div class="sm:w-2/3 sm:pl-8 sm:py-8  mt-4 pt-4 sm:mt-0 text-center sm:text-left">
          <h1 class="font-medium title-font mt-4 text-2xl" style="color: orangered;">
            Why us?
          </h1>
          <br />
          <p class="leading-relaxed text-xl text-justify font-black-100">
            Customer satisfaction is our primary goal, so we provide 24/7
            assistance. We focus on cutting edge and result-driven programming,
            making the tool aesthetic. We deliver our profound business
            solutions with the utmost integrity. We don’t outsource or involve
            any third party in our organization, so there are no risks involved
            with data privacy. We help our clients with our creative ideas to
            make their vision appealing to stand out in the market. Besides
            this, we understand time is money, so we guarantee on-time delivery.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
