<!DOCTYPE html>
<html>

<head>
  <title>Tailwind CSS Carousel</title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss/dist/tailwind.min.css" rel="stylesheet" />
</head>

<body>
  <section class="text-gray-700 body-font">
    <div class="container px-5 py-14 mx-auto flex flex-col">
      <div class="lg:w-4/6 mx-auto">
        <!--      <div class="rounded-lg h-64 overflow-hidden">-->
        <!--        <img alt="content" class="object-cover object-center h-full w-full" lazyLoad="../../assets/new-back.png" style="alignment:center;width:600px;height:300px;">-->
        <!--      </div>-->
        <div class="flex flex-col sm:flex-row mt-10">
          <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
            <div class="flex flex-col items-center text-center justify-center">
              <img alt="team" class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4"
                src="../../assets/Images/teammembers/monalisa.png" />
              <h2 class="font-medium title-font mt-4 text-gray-900 text-lg text-justify">
                Monalisa Patnaik
              </h2>
              <h1 class="text-blue-900">Founder</h1>
              <div class="w-12 h-1 bg-orange-500 rounded mt-2 mb-4"></div>
              <p class="text-lg font-black-100 text-justify">
                Welcome to BriTechSoft!
              </p>
            </div>
          </div>
          <div
            class="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-300 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
            <p class="leading-relaxed text-lg text-justify font-black-100">
              BriTechSoft - BRILLIANT TECHNOLOGY SOFTWARE is a software
              company that focuses on providing smart services and solutions
              based upon customer requirements.
            </p>
            <p class="font-weight-bold font-black-100">
              WE BELIEVE IN CUSTOMER CENTRIC APPROACH.
            </p>
            <p class="leading-relaxed text-lg mb-4 text-justify font-black-100">
              The solutions we provide to customers are from the categories
              such as website, mobile, standalone and automation development
              application.
            </p>
            <a class="atagbutton" href="/aboutus" routerLinkActive="active">Know
              More
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</body>

</html>
