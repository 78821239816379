<section class="text-gray-700 body-font">
  <div class="container px-5 py-4 mx-auto flex flex-col">
    <div class="mx-auto">
      <!--      <div class="rounded-lg h-64 overflow-hidden">-->
      <!--        <img alt="content" class="object-cover object-center h-full w-full" lazyLoad="../../assets/new-back.png" style="alignment:center;width:600px;height:300px;">-->
      <!--      </div>-->

      <div class="flex flex-col sm:flex-row mt-10">
        <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
          <div class="flex flex-col items-center text-center justify-center">
            <img class="h-full" style="width: 700px; height: 300px;"
              lazyLoad="../../assets/Images/Textimages/whatwedo.png" />
            <!-- <div class="w-12 h-1 bg-orange-500 rounded mt-2 mb-4"></div> -->
          </div>
        </div>

        <div class="sm:w-2/3 sm:pl-8 sm:py-8  mt-4 pt-4 sm:mt-0 text-center sm:text-left">
          <h1 class="font-medium title-font mt-4 text-2xl" style="color: orangered;">
            What we do?
          </h1>
          <br />
          <p class="leading-relaxed text-lg text-justify font-black-100">
            We mainly focus on software development, which includes Website,
            Mobile application, standalone, and automation development.
            Currently, we have a few on-going projects on Web development from
            various industries, which include Education, E-commerce, and
            Finance.
          </p>
        </div>
      </div>
      <div class="w-full h-1 bg-gray-100 rounded mt-2 mb-4"></div>
      <div class="flex flex-col sm:flex-row mt-10">
        <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
          <div class="flex flex-col items-center text-center justify-center">
            <img style="width: 700px; height: 300px; margin-top: 70px;"
              lazyLoad="../../assets/Images/Textimages/howwedo.png" />

            <!-- <div class="w-12 h-1 bg-orange-500 rounded mt-2 mb-4"></div> -->
          </div>
        </div>
        <div class="sm:w-2/3 sm:pl-8 sm:py-8  mt-4 pt-4 sm:mt-0 text-center sm:text-left">
          <h1 class="font-medium title-font mt-4 text-2xl" style="color: orangered;">
            How we do?
          </h1>
          <br />
          <p class="text-lg text-left font-black-100">
            Our solutions are either innovation or technology driven based on
            the use case.
          </p>
          <br />
          <p style="text-align: left; color: blue;">
            We accept requirements on a few scenarios such as
          </p>
          <ul class="bullet font-black-100">
            <li>Revamp and Maintenance of existing products and services.</li>
            <li>Sharpening of ideas.</li>
            <li>Cutting edge technology.</li>
            <li>Intelligent automation.</li>
          </ul>
          <br />
          <p style="text-align: left; color: blue;">
            Processes and Technologies:
          </p>
          <ul class="bullet font-black-100">
            <li>Python, C#, Java, MATLAB</li>
            <li>Angular, HTML, CSS, Angular</li>
            <li>Android, iOS</li>
            <li>Artificial Intelligence, Machine learning</li>
            <li>Test-driven development</li>
            <li>Agile, V-shaped Model</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
