import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BritechsoftComponent } from './britechsoft/britechsoft.component';
import { AboutComponent } from './britechsoft/about/about.component';
import { ContactComponent } from './britechsoft/contact/contact.component';
import { FooterComponent } from './britechsoft/footer/footer.component';
import { HeaderComponent } from './britechsoft/header/header.component';
import { HomeComponent } from './britechsoft/home/home.component';
import { PrivacyPolicyComponent } from './britechsoft/privacy-policy/privacy-policy.component';
import { ProductComponent } from './britechsoft/product/product.component';
import { CareerComponent } from './britechsoft/career/career.component';
import { ImageCarouselComponent } from './britechsoft/image-carousel/image-carousel.component';
import { TechnologyComponent } from './britechsoft/technology/technology.component';
import { OurTeamComponent } from './britechsoft/our-team/our-team.component';
import { AboutWhoWhyComponent } from './britechsoft/about-who-why/about-who-why.component';
import { HomeWhatWhyComponent } from './britechsoft/home-what-why/home-what-why.component';
import { ContactQueryComponent } from './britechsoft/contact-query/contact-query.component';
const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: BritechsoftComponent, pathMatch: 'full' },
      { path: 'aboutus', component: AboutComponent },
      { path: 'jobapplication', component: ContactComponent },
      { path: 'footer', component: FooterComponent },
      { path: 'header', component: HeaderComponent },
      { path: 'home', component: HomeComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'product', component: ProductComponent },
      { path: 'career', component: CareerComponent },
      { path: 'image-carousel', component: ImageCarouselComponent },
      { path: 'technology', component: TechnologyComponent },
      { path: 'our-team', component: OurTeamComponent },
      { path: 'about-who-why', component: AboutWhoWhyComponent },
      { path: 'home_what_why', component: HomeWhatWhyComponent },
      { path: 'contactus', component: ContactQueryComponent }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
