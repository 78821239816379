<div fxLayout="column" class="scroll-hidden bgWhite" layout-align="start stretch" style="margin-top: 50px">
  <h1 class="text-2xl font-medium title-font mb-4 text-blue-900 tracking-widest" style="padding: 55px">PRIVACY POLICY
  </h1>
  <section class="text-gray-700 body-font">
    <div class="container px-5 py-14 mx-auto">
      <div class="flex flex-col">
        <p class="sm:w-3/5 leading-relaxed text-lg sm:pl-10 pl-0">
          BriTechSoft collects and uses Users' personal information for the following purposes -
        </p>
        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
          <h3 class="sm:w-2/5 text-blue-900 font-small title-font text-lg mb-2 sm:mb-0">Individual User Information</h3>
          <p class="sm:w-3/5 leading-relaxed text-lg sm:pl-10 pl-0">
            We may monitor user information to understand the usage of our services and products provided by our team on
            our website.
          </p>
        </div>
        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
          <h3 class="sm:w-2/5 text-blue-900 font-medium title-font text-lg mb-2 sm:mb-0">BriTechSoft Website
            improvisation</h3>
          <p class="sm:w-3/5 leading-relaxed text-lg sm:pl-10 pl-0">
            We are consistently trying to upgrade our website features and services based on the responses we receive
            from our users.
          </p>
        </div>
        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
          <h3 class="sm:w-2/5 text-blue-900 font-medium title-font text-lg mb-2 sm:mb-0">Processing transaction</h3>
          <p class="sm:w-3/5 leading-relaxed text-lg sm:pl-10 pl-0">
            We maintain User’s data to provide them the required service as per their demand. We do not share the User’s
            information with any third party unless highly unavoidable instances occur.
            In such inevitable situations, we will inform the User in advance.
          </p>
        </div>
        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
          <h3 class="sm:w-2/5 text-blue-900 font-medium title-font text-lg mb-2 sm:mb-0"> Protection of User data</h3>
          <p class="sm:w-3/5 leading-relaxed text-lg sm:pl-10 pl-0">
            We follow the best development practices to protect user data. At the same time, we use relevant data
            collections,
            security measures, storage, and hosting to protect the information against unauthorized access, alteration,
            or disfigurement of user data like username, password, gender, email, phone number, and transaction details.
            We always store the User passwords in an encrypted format with us.
            Any sensitive and personal or private data exchange is carried out over an SSL secured channel.

          </p>
        </div>
        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
          <h3 class="sm:w-2/5 text-blue-900 font-medium title-font text-lg mb-2 sm:mb-0"> Sharing of User data</h3>
          <p class="sm:w-3/5 leading-relaxed text-lg sm:pl-10 pl-0">
            We do not trade, sell, or rent User’s personal identification information to others.
            We may use third-party service providers to help us operate our business and the Site or administer
            activities on our behalfs,
            such as sending out newsletters or surveys.
            We may share your information with these third parties for those limited purposes provided that you have
            given us prior permission.
          </p>
        </div>
        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
          <h3 class="sm:w-2/5 text-blue-900 font-medium title-font text-lg mb-2 sm:mb-0"> Alteration in privacy policy
          </h3>
          <p class="sm:w-3/5 leading-relaxed text-lg sm:pl-10 pl-0">
            BriTechSoft may update the privacy policy at any time.
            When we do, we will notify you by adding the updated date at the top of our Privacy Policy page.
            We would request our users to frequently check the page for any such changes to stay informed.
            You acknowledge and agree that it is your responsibility to review this privacy policy and become aware of
            alterations occasionally
          </p>
        </div>
        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
          <h3 class="sm:w-2/5 text-blue-900 font-medium title-font text-lg mb-2 sm:mb-0"> User acceptance to policy</h3>
          <p class="sm:w-3/5 leading-relaxed text-lg sm:pl-10 pl-0">
            Using the Site, you signify your assent to our policy and terms of the service provided by BriTechSoft.
            If you disagree with our policy, please feel free to opt-out of our services or contact us to know a
            suitable alternative service for you.
          </p>
        </div>
        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
          <h3 class="sm:w-2/5 text-blue-900 font-medium title-font text-lg mb-2 sm:mb-0"> Self-reliant Campaign </h3>
          <p class="sm:w-3/5 leading-relaxed text-lg sm:pl-10 pl-0">
            We are authorized by MSME, a branch of Government of India, and a self-reliant support campaign started by
            the Government of India during
            Corona Pandemic to make the country as well as the world a better place.
            We request you to join us in this campaign and make the world a smaller place by connecting digitally.
          </p>
        </div>
        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
          <h3 class="sm:w-2/5 text-blue-900 font-medium title-font text-lg mb-2 sm:mb-0"> Customer Service</h3>
          <p class="sm:w-3/5 leading-relaxed text-lg sm:pl-10 pl-0">
            Users suggestions help us providing better and quick customer support.
          </p>
        </div>
        <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
          <p class="leading-relaxed text-lg" style="padding: 15px">If you have any further queries related to the
            privacy policy, please
            <a style="color:blue;" routerLink="/contactus" href="#" routerLinkActive="active">contact us</a> here.
          </p>
        </div>
      </div>
    </div>

  </section>
</div>
