import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-who-why',
  templateUrl: './about-who-why.component.html',
  styleUrls: ['./about-who-why.component.scss']
})
export class AboutWhoWhyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
