<!DOCTYPE html>
<html>

<head>
  <title>Tailwind CSS Carousel</title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss/dist/tailwind.min.css" rel="stylesheet">
</head>

<body>
  <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 font-style-css">Our Products</h1>
  <section class="text-gray-700 body-font">
    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-wrap -mx-4 -mb-10 text-center">
        <div class="sm:w-1/2 mb-10 px-4">
          <div class="rounded-lg h-64 overflow-hidden">
            <img alt="content" class="object-cover object-center h-full w-full" lazyLoad="../../assets/logo.png">
          </div>
          <h2 class="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">Calculate your Money</h2>
          <p class="leading-relaxed text-base">Simplified tool for the calculation of your daily expenses. (Explain it
            here properly)</p>
          <button
            class="flex mx-auto mt-6 text-white bg-orange-500 border-0 py-2 px-5 focus:outline-none hover:bg-orange-600 rounded">Go
            To Website</button>
        </div>
        <div class="sm:w-1/2 mb-10 px-4">
          <div class="rounded-lg h-64 overflow-hidden">
            <img alt="content" class="object-cover object-center h-full w-full" lazyLoad="../../assets/BTS.png">
          </div>
          <h2 class="title-font text-2xl font-medium text-gray-900 mt-6 mb-3">Spice Inn</h2>
          <p class="leading-relaxed text-base">Application to order your food online. (Write more about the application
            later)</p>
          <button
            class="flex mx-auto mt-6 text-white bg-orange-500 border-0 py-2 px-5 focus:outline-none hover:bg-orange-600 rounded">Go
            To Website</button>
        </div>
      </div>
    </div>
  </section>
</body>

</html>
