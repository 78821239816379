<div fxLayout="column" class="scroll-hidden bgWhite" layout-align="start stretch" style="margin-top: 50px">
  <h1 class="text-2xl font-medium title-font mb-4 text-blue-900 tracking-widest"
    style="text-align: center; padding-top: 25px">WELCOME TO BRITECHSOFT</h1>
  <section class="text-gray-700 body-font">
    <img class="w-full"alt="bts" lazyLoad="../../assets/Images/Textimages/front.jpg" />
    <div class="container px-5 py-14 mx-auto">
      <div class="flex flex-col text-center w-full mb-20">
        <div class="p-2 w-full pt-8 mt-8 text-center leading-relaxed text-lg mb-4">

          <app-home-what-why></app-home-what-why>
        </div>
      </div>

    </div>
  </section>

  <div class="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center leading-relaxed text-lg mb-4">
    <h1 class="text-2xl font-medium title-font mb-4 text-blue-900 tracking-widest" style="padding: 35px;">
      TECHNOLOGIES & TOOLS
    </h1>
    <app-technology></app-technology>
  </div>

  <div class="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center leading-relaxed text-lg mb-4">
    <app-image-carousel></app-image-carousel>
  </div>
</div>
