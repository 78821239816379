import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-what-why',
  templateUrl: './home-what-why.component.html',
  styleUrls: ['./home-what-why.component.scss']
})
export class HomeWhatWhyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
