<footer class=" body-font footer hdftbgColor">
  <div class=" flex-row d-flex justify-content-between hdftbgColor" style=" margin-left: 5px;">
    <div class=" d-flex">
      <p class="text-white mr-0 text-xs">
        © 2020 BriTechSoft, All rights Reserved
      </p>
      <a class="text-white text-xs" style="margin-left:20px;margin-right: 20px;" href="#"
        routerLink="/privacy-policy">Privacy Policy</a>

    </div>
    <div class="d-flex">
      <div class="inline-flex sm:ml-auto col">
        <a class="text-white" title="Follow us on facebook" href="https://www.facebook.com/britechsoft">
          <em class="fab fa-facebook-f left text-white"></em>
        </a>

        <a class="ml-3 text-white" title="Follow us on instagram" href="https://www.instagram.com/britechsoft/">
          <em class="fab fa-instagram left text-white"></em>
        </a>
        <a class="ml-3 text-white" title="Follow us on twitter" href="https://twitter.com/soft_bri">
          <em class="fab fa-twitter left text-white"></em>
        </a>
        <a class="ml-3 text-white" title="Follow us on linkedin"
          href="https://in.linkedin.com/in/britechsoft?trk=profile-badge">
          <em class="fab fa-linkedin left text-white"></em>
        </a>
      </div>
    </div>
  </div>
</footer>
