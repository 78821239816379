<div fxLayout="column" class="scroll-hidden bgWhite" layout-align="start stretch" style="margin-top: 50px">
  <h1 class="text-2xl font-medium title-font mb-4 text-blue-900 tracking-widest"
    style="text-align: center; padding: 25px">ABOUT US</h1>
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <app-about-who-why></app-about-who-why>

      <div class="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center leading-relaxed text-lg mb-4">
        <h1 class="text-2xl font-medium title-font mb-4 text-blue-900 tracking-widest" style="padding: 35px;">
          OUR INDUSTRIES
        </h1>
        <img class="h-full w-full" lazyLoad="../../assets/Images/Textimages/industries.png" />
      </div>
    </div>
  </div>
  <div id="ourteam" class="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center leading-relaxed text-lg mb-4">
    <app-our-team></app-our-team>
  </div>
</div>
