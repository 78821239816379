<!DOCTYPE html>
<html>

<head>
  <title>BriTechSoft</title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss/dist/tailwind.min.css" rel="stylesheet" />
</head>

<body class="font-sans leading-normal tracking-normal bgWhite">
  <header class="body-font hdftbgColor">
    <div class=" d-flex p-1 fixed-top flex-wrap align-content-stretch text-sm hdftbgColor">
      <a class="title-font font-medium items-left">
        <!--<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
      </svg>-->
        <div class="d-flex">
          <img class="w-full" lazyLoad="../../assets/britechsoftlogo.jpg" style="width: 50px;" alt="BriTechSoft" href="#"
            [routerLink]="['/']" />
        </div>
      </a>
      <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center" style="padding-left: 10px;">
        <a class="mr-5 text-white  items-center" href="#" routerLink="/" [routerLinkActiveOptions]="{exact:true}"
          routerLinkActive="active">Home</a>
        <a class="mr-5 text-white  items-center" href="#" routerLink="/aboutus" [routerLinkActiveOptions]="{exact:true}"
          routerLinkActive="active">About</a>
        <a class="mr-5 text-white  items-center" href="#" routerLink="/contactus"
          [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">Contact</a>
        <a class="mr-5 text-white  items-center" href="#" routerLink="/career" [routerLinkActiveOptions]="{exact:true}"
          routerLinkActive="active">Career</a>
      </nav>
    </div>
  </header>
</body>



</html>
