<div fxLayout="column" class="scroll-hidden bgWhite" layout-align="start stretch" style="margin-top: 50px">
  <h1 class="text-2xl font-medium title-font mb-4 text-blue-900 tracking-widest"
    style="text-align: center; padding-top: 25px; background-color: white">
    CAREER <br> </h1>

  <section class="text-gray-700 body-font overflow-hidden">
   <!-- <h2 style="color: orangered; text-align: center"> We are hiring Now!</h2>-->
    <div class="container px-5 py-15 mx-auto">
      <img class="w-50 center" alt="contact us!" lazyLoad="../../assets/Images/hiring.jpg" />
      <br/>
      <br/>
      <div class="-my-8">
        <div class="py-8 flex flex-wrap md:flex-no-wrap">
          <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
            <span class="tracking-widest font-medium title-font text-gray-900">Technical Opening</span>
            <span class="mt-1 text-gray-500 text-sm">28 September 2020</span>
            <h3 class="leading-relaxed text-base"><b>Job ID: 280901</b></h3>
          </div>
          <div class="md:flex-grow">
            <h2 class="text-2xl font-medium text-gray-900 title-font mb-2">Software Development Internship</h2>

            <h3 class="leading-relaxed text-base font-blue-100"><b>About the internship</b></h3>
           <div class="font-black-100"> Selected intern's day-to-day responsibilities include:</div>
            <ul class="bullet font-black-100">
              <li>Creating and maintaining software documentation.</li>
              <li>Analysis and creation of Proof of Concepts on cutting edge technologies.</li>
              <li>Development of the assigned usecases.</li>
              <li>Automation of the manual tools and softwares.</li>
            </ul>
            <h3 class="leading-relaxed text-base font-blue-100"><b>Skills Required</b></h3>
            <p class="font-black-100">Good Written and Spoken communication Skills in English or Hindi. </p>
            <h3 class="leading-relaxed text-base font-blue-100"><b>Who can Apply?</b></h3>
            <ul class="bullet font-black-100">
              <li>Minimum age 19 or above.</li>
              <li>Bachelors/ Diploma (CS & IT)/ BCA/ MCA.</li>
              <li>are available for duration of 6 months.</li>
              <li>Good knowledge on any one of the languages.
                Java, Python, C, C++, HTML/CSS
              </li>
              <li>Ability to understand our technical points and gain hands on experience</li>
              <li>Excellent analytical and problem-solving skills</li>
              <li>Must have own computer or Laptop with Internet connection.</li>
              <li>Ready to work remotely and a quick learner.

              </li>
              <li>
                Disciplined, Focused, work ethic and result oriented.
              </li>
            </ul>
            <h3 class="leading-relaxed text-base font-blue-100"><b>What do you gain?</b></h3>
            <ul class="bullet font-black-100">
              <li> Hands on Experience on Live Projects with International clients.</li>
              <li> No Need to Travel, Work from home, Flexible working hours.</li>
              <li> Complete Transparency and Direct Discussion with the CEO and the other Co-Founders.</li>
            </ul>
            <h3 class="leading-relaxed text-base font-blue-100"><b>Number of Openings</b></h3>
            <p> 1</p>


            <a class="atagbutton" href="/jobapplication">Apply Now

            </a>
          </div>
        </div>
        <div class="py-8 flex border-t-2 border-gray-200 flex-wrap md:flex-no-wrap">
          <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
            <span class="tracking-widest font-medium title-font text-gray-900">Non Technical Opening</span>
            <span class="mt-1 text-gray-500 text-sm">28 September 2020</span>
            <h3 class="leading-relaxed text-base"><b>Job ID: 280902</b></h3>

          </div>
          <div class="md:flex-grow" style="padding-bottom: 50px">
            <h2 class="text-2xl font-medium text-gray-900 title-font mb-2">Business Development (Sales Executive)</h2>
            <h3 class="leading-relaxed text-base font-blue-100"><b>About the Sales Job</b></h3>
            <div class="font-black-100"> Selected employee's day-to-day responsibilities include:</div>
            <ul class="bullet font-black-100">
              <li>Handling customer questions, inquiries, and complaints.</li>
              <li>Preparing and sending quotes and proposals.</li>
              <li>Negotiate on price, costs, delivery and specifications with buyers and managers</li>
              <li>Understand the current marketing strategies and implement the same in the organization. </li>
              <li>Work collaboratively with the End Customers and Cross Functional Teams.</li>
            </ul>
            <h3 class="leading-relaxed text-base font-blue-100"><b>Skills Required</b></h3>
            <p class="font-black-100"> Good communication skills in English and Hindi or other different regional languages are must. </p>

            <h3 class="leading-relaxed text-base font-blue-100"><b>Who can Apply?</b></h3>
            <ul class="bullet font-black-100">
              <li>Minimum age 20 or above.</li>
              <li>Educational Background: Successful completion of 12th / Diploma / BBA.</li>
              <li>Atleast available for a duration of 6 months.</li>
              <li>Proficient in marketing techniques, Excellent customer service and sales skills.</li>
              <li>Ability to lead a team and attitude towards operational excellence. </li>
              <li>A proper channeling between the customers and the product owner to have a good relation with the
                clients. </li>
              <li>1+ years of sales experience (Optional)</li>
              <li>Good negotiation and problem-solving skills. </li>
              <li>Commercial awareness. </li>
              <li>Disciplined, Focused and Result oriented. </li>
            </ul>
            <h3 class="leading-relaxed text-base font-blue-100"><b>What do you gain?</b></h3>
            <ul class="bullet font-black-100">
              <li> Hands on Experience on Live Projects with International clients.</li>
              <li> Good Incentive structure.</li>
              <li> Part time and based on performance possibility to become a full time employee.</li>
              <li> Flexible working hours.</li>
              <li> Complete Transparency and Direct Discussion with the CEO and the other Co-Founders.</li>
            </ul>
            <h3 class="leading-relaxed text-base font-blue-100"><b>Number of Openings</b></h3>
            <p> 1</p>
            <a class="atagbutton" href="/jobapplication">Apply Now
            </a>

          </div>
        </div>
      </div>
    </div>
  </section>

</div>
